import React from "react";

const dateFormat = new Intl.DateTimeFormat("en-US", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export const Date = ({ date }: { date?: Date }) => {
  return <time dateTime={date?.toISOString()}>{dateFormat.format(date)}</time>;
};
