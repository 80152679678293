import { Flex } from "@chakra-ui/react";
import React from "react";

import { SUBSCRIBE_URL, TITLE } from "../../renderer/constants";
import { Link as BlogLink } from "../Link";

export const Footer = () => {
  return (
    <Flex
      as="footer"
      mx="auto"
      mt={10}
      mb={40}
      w="full"
      maxW="40em"
      py={10}
      direction="column"
      align="center"
      gap={1}
      opacity={0.5}
      transition="0.3s opacity"
      _hover={{ opacity: 1 }}
      _focusWithin={{ opacity: 1 }}
    >
      <p>
        I&rsquo;m <BlogLink href="https://cjquines.com/">CJ Quines,</BlogLink>{" "}
        and you&rsquo;re reading <BlogLink href="/">{TITLE}.</BlogLink>
      </p>
      <p>
        Subscribe <BlogLink href="/atom.xml">via RSS.</BlogLink> Subscribe{" "}
        <BlogLink href={SUBSCRIBE_URL}>via email.</BlogLink>
      </p>
    </Flex>
  );
};
