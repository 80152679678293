import { Link as ChakraLink } from "@chakra-ui/react";
import React from "react";

import { useColors } from "./useColors";

export const Link = (props: {
  href?: string;
  className?: string;
  children: React.ReactNode;
}) => {
  const { href, children } = props;

  const isExternal = !!href?.startsWith("http");
  const isAnchor = !!href?.startsWith("#");
  const { linkColor, linkBackground } = useColors();

  return (
    <ChakraLink
      className={isExternal ? "external" : isAnchor ? "anchor" : "internal"}
      color={linkColor}
      isExternal={isExternal}
      _hover={{
        textDecoration: "none",
        bg: linkBackground,
      }}
      _focus={{ bg: linkBackground }}
      {...props}
    >
      {children}
    </ChakraLink>
  );
};
