import { Box, Grid, Link, Text } from "@chakra-ui/react";
import React, { Fragment } from "react";
import { Balancer } from "react-wrap-balancer";

import { PostMeta } from "../../renderer/types";
import { Date } from "../Date";
import { useColors } from "../useColors";

export const Header = ({ meta }: { meta?: PostMeta }) => {
  const { title, subtitle, date, tags = [] } = meta ?? {};
  const { subtitleColor } = useColors();

  return (
    <Box mb={10} as="header">
      <Grid
        templateColumns="max-content 1fr"
        alignItems="center"
        justifyItems="stretch"
        gap={1}
      >
        <Text
          fontFamily="sans"
          fontSize="sm"
          fontWeight="medium"
          color={subtitleColor}
        >
          <Text as="i">by </Text>
          <Link href="https://cjquines.com/">CJ Quines</Link>
          <Text as="span"> • </Text>
          <Text as="i">on </Text>
          <Date date={date} />
          {tags.length > 0 ? (
            <>
              <Text as="span"> • </Text>
              <Text as="i">in </Text>
            </>
          ) : null}
          {tags.map((tag, i) => (
            <Fragment key={tag}>
              <Link href="#tagpage">{tag}</Link>
              {i < tags.length - 1 ? ", " : ""}
            </Fragment>
          ))}
        </Text>
        <Box h={0.5} bg={subtitleColor} borderRadius="full" />
      </Grid>
      <Text as="h1" textStyle="h1" mt={5}>
        <Balancer>{title}</Balancer>
      </Text>
      <Text fontSize="lg" fontStyle="italic" mt={1}>
        <Balancer>{subtitle}</Balancer>
      </Text>
    </Box>
  );
};
