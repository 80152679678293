import React, { PropsWithChildren, createContext } from "react";

const defaultVariant = {
  tableWrap: false,
};

type VariantProps = typeof defaultVariant;

export const VariantContext = createContext<VariantProps>(defaultVariant);

export const Variant = ({
  children,
  ...variants
}: PropsWithChildren<Partial<VariantProps>>) => (
  <VariantContext.Provider value={{ ...defaultVariant, ...variants }}>
    {children}
  </VariantContext.Provider>
);
