import { Root, createRoot, hydrateRoot } from "react-dom/client";

import { consumeContext } from "./consumeContext";
import { PageContextClient } from "./types";

let root: Root;

const render = async (pageContext: PageContextClient) => {
  const { isHydration } = pageContext;
  const { page, pageTitle } = consumeContext(pageContext);

  document.title = pageTitle;

  const container = document.getElementById("react-root")!;
  if (isHydration) {
    root = hydrateRoot(container, page);
  } else {
    if (!root) {
      root = createRoot(container);
    }
    root.render(page);
  }
};

export default render;
