import {
  ChakraTheme,
  ThemeConfig,
  extendTheme,
  withDefaultColorScheme,
} from "@chakra-ui/react";
import { mode } from "@chakra-ui/theme-tools";

const config: ThemeConfig = {
  initialColorMode: "system",
};

const baseTheme: Partial<ChakraTheme> = {
  fonts: {
    body: `"IBM Plex Serif", serif`,
    mono: `"IBM Plex Mono", monospace`,
    sans: `"IBM Plex Sans", sans-serif`,
  },
  space: {
    parSpace: "0.9em",
  },
  textStyles: {
    h1: {
      fontSize: "1.5em",
      fontWeight: "bold",
      lineHeight: "shorter",
    },
    h2: {
      fontFamily: "sans",
      fontSize: "1.4em",
      fontWeight: "bold",
      lineHeight: "short",
    },
    h3: {
      fontFamily: "sans",
      fontSize: "1.2em",
      fontWeight: "medium",
    },
    sc: {
      textTransform: "uppercase",
      fontWeight: "bold",
      fontSize: "0.85em",
      letterSpacing: "0.08em",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        color: mode("gray.900", "whiteAlpha.900")(props),
        bg: mode("white", "gray.900")(props),
      },
      pre: { fontFamily: "mono" },
      code: { fontFamily: "mono" },
    }),
  },
};

export const theme = extendTheme(
  baseTheme,
  config,
  withDefaultColorScheme({ colorScheme: "orange" })
);
