import React from "react";

import { PageShell } from "../components/PageShell";
import { Post } from "../components/Post";
import { postMeta } from "../pages/postMeta";
import { TITLE } from "./constants";
import { PageContext } from "./types";

export const consumeContext = (pageContext: PageContext) => {
  const { Page, urlPathname } = pageContext;

  if (!urlPathname.startsWith("/post/")) {
    const page = (
      <PageShell pageContext={pageContext}>
        <Page />
      </PageShell>
    );
    return { page, pageTitle: TITLE };
  }

  const metaIdx = postMeta.findIndex(
    ({ url }) => url === urlPathname || url === urlPathname.slice(0, -1),
  );

  // yes, reversed
  const prevMeta = postMeta[metaIdx + 1];
  const meta = postMeta[metaIdx];
  const nextMeta = postMeta[metaIdx - 1];

  const page = (
    <PageShell pageContext={pageContext}>
      <Post prevMeta={prevMeta} meta={meta} nextMeta={nextMeta}>
        <Page />
      </Post>
    </PageShell>
  );

  const pageTitle = meta?.title ? `${meta.title} | ${TITLE}` : `${TITLE}`;

  return { page, pageTitle, meta };
};
