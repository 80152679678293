import {
  Box,
  Spinner,
  SystemStyleObject,
  Text,
  useColorModeValue,
  useMultiStyleConfig,
  useStyleConfig,
  useTheme,
} from "@chakra-ui/react";
import { mergeDeepRight } from "ramda";
import React, { useEffect, useState } from "react";

import { SITE_URN } from "../../renderer/constants";
import { PostMeta } from "../../renderer/types";
import { useColors } from "../useColors";

const useSx = () => {
  const theme = useTheme();
  const {
    borderColor,
    checkboxBackground,
    checkboxBackgroundHover,
    linkColor,
    linkBackground,
    subtitleColor,
  } = useColors();
  const checkboxStyles = useMultiStyleConfig("Checkbox");
  const inputStyles = useMultiStyleConfig("Input");

  const checkboxBoxShadow = useColorModeValue(
    `inset 1em 1em ${theme.colors.white}`,
    `inset 1em 1em ${theme.colors.gray[900]}`
  );

  /** General styles. */
  const styles = {
    buttonLink: {
      background: "transparent",
      color: linkColor,
      cursor: "pointer",
      fontFamily: "sans",
      fontWeight: "bold",
      transition: "0.2s background",

      "&:hover, &:focus": {
        textDecoration: "none",
        background: linkBackground,
      },
    },
    checkboxCheckbox: {
      ...checkboxStyles.control,
      appearance: "none",
      cursor: "pointer",
      display: "grid",
      placeContent: "center",
      transition: "0.2s background",

      "&:hover": { background: linkBackground },

      "&:checked": {
        background: checkboxBackground,
        borderColor: checkboxBackground,
      },
      "&:hover:checked": {
        background: checkboxBackgroundHover,
        borderColor: checkboxBackgroundHover,
      },

      "&::before": {
        content: '""',
        boxShadow: checkboxBoxShadow,
        clipPath:
          "polygon(14% 44%, 0 65%, 50% 100%, 100% 16%, 80% 0%, 43% 62%)",
        height: "calc(var(--checkbox-size) * 0.65)",
        transform: "scale(0)",
        transformOrigin: "bottom left",
        transition: "0.2s transform ease-in-out",
        width: "calc(var(--checkbox-size) * 0.65)",
      },
      "&:checked::before": { transform: "scale(1)" },
    },
    checkboxContainer: {
      ...checkboxStyles.container,
      ...checkboxStyles.label,
      alignItems: "center",
      fontFamily: "sans",
      cursor: "pointer",
      display: "grid",
      gap: "0.5rem",
      gridTemplateColumns: "min-content auto",
    },
    inputGroup: {
      ...inputStyles.group,
      display: "flex",
      isolation: "isolate",
      position: "relative",
      width: "100%",
    },
    inputField: {
      ...inputStyles.field,
      borderStartRadius: 0,
      paddingX: 3,
    },
    inputAddon: {
      ...inputStyles.addon,
      alignItems: "center",
      borderEndColor: "transparent",
      borderEndRadius: 0,
      display: "flex",
      flex: "0 0 auto",
      fontFamily: "sans",
      justifyContent: "center",
      marginEnd: "-1px",
      whiteSpace: "nowrap",
      width: "4em",
    },
    textarea: {
      ...useStyleConfig("Textarea"),
      paddingX: 3,
    },
  } as const satisfies Record<string, SystemStyleObject>;

  /** Styles for showing / hiding things: */
  const sxShowHide: SystemStyleObject = {
    /** Hide "One comment" heading: */
    ".isso-thread-heading": { display: "none" },

    /** Hide Preview / Edit buttons, and Preview box: */
    ".isso-post-action:nth-of-type(5)": { display: "none" },
    ".isso-postbox .isso-comment": { display: "none" },
    ".isso-post-action:nth-of-type(6)": { display: "none" },

    /** Hide Website input: */
    ".isso-input-wrapper:nth-of-type(3)": { display: "none" },

    /** Don't make it occupy space if it's empty: */
    ".isso-note": { display: "contents" },

    /** Show email notification checkbox: */
    ".isso-notification-section": { display: "block !important" },
  };

  /** Styles for individual elements. */
  const sxEltStyles: SystemStyleObject = {
    /** Element replacements: */
    'input[type="submit"]': styles.buttonLink,

    ".isso-notification-section > label": styles.checkboxContainer,
    'input[type="checkbox"]': styles.checkboxCheckbox,

    ".isso-input-wrapper": styles.inputGroup,
    'input[type="text"]': styles.inputField,
    'input[type="email"]': styles.inputField,
    ".isso-input-wrapper > label": styles.inputAddon,

    ".isso-textarea": styles.textarea,

    /** Comment meta: */
    ".isso-comment-header": {
      color: subtitleColor,
      fontFamily: "sans",
      fontSize: "sm",
      fontWeight: "medium",
    },
    ".isso-author": {
      "&::before": {
        content: '"by "',
        fontStyle: "italic",
      },
    },

    /** Comment actions: */
    ".isso-comment-footer > a": {
      ...styles.buttonLink,
      marginLeft: 1,
      fontSize: "sm",
      fontWeight: "medium",
      textTransform: "lowercase",

      "&:nth-of-type(n + 2)::before": { content: '"• "' },
    },

    /** Nested comment border: */
    ".isso-follow-up": {
      borderLeftWidth: 3,
      position: "relative",

      "& > .isso-comment:nth-of-type(1)::before": {
        content: '""',
        position: "absolute",
        top: `-${theme.sizes[1]}`,
        left: `calc(-${theme.sizes[1]} - 3px / 2)`,
        background: borderColor,
        borderRadius: "full",
        height: 2,
        width: 2,
      },
    },
  };

  /** Styles for layouting. */
  const sxLayoutStyles: SystemStyleObject = {
    /** Postbox layouting: */
    ".isso-postbox": { marginTop: 3, marginBottom: 6 },
    ".isso-form-wrapper": {
      display: "grid",
      gridTemplateColumns: "1fr 1fr",
      gap: theme.space[2],

      "@media (max-width:720px)": { gridTemplateColumns: "1fr max-content" },
    },
    ".isso-textarea-wrapper": { gridColumn: "1 / 3" },
    ".isso-auth-section": { display: "contents" },
    ".isso-post-action:nth-of-type(4)": {
      gridColumn: "2 / 3",
      justifySelf: "end",
    },
    ".isso-input-wrapper": {
      "@media (max-width:720px)": { gridColumn: "1 / 3" },
    },
    ".isso-notification-section": {
      gridColumn: "1 / 2",
      gridRow: "3 / 4",

      "@media (max-width:720px)": {
        gridRow: "4 / 5",
      },
    },

    /** Root layouting: */
    "#isso-root": { marginTop: 10 },

    /** Comment layouting: */
    ".isso-follow-up": {
      marginTop: 2,
      marginLeft: 1,
      marginBottom: 4,
      paddingLeft: 3,
    },
    ".isso-text-wrapper": {
      padding: `calc(${theme.space.parSpace} / 2) 0`,
      display: "grid",
      gridGap: `${theme.space[1]} 0`,

      gridTemplateColumns: "max-content 1fr",
      alignItems: "baseline",

      "&:nth-of-type(1)": { paddingTop: 0 },
      "&:nth-of-type(-1)": { paddingBottom: 0 },
    },
    ".isso-text": {
      gridColumn: "1 / 3",
      "& > p + p": { marginTop: "parSpace" },
    },
    ".isso-comment-header": {
      display: "flex",
      alignItems: "center",
      gridColumn: "1 / 2",
      gridRow: "2 / 3",
      gap: theme.space[1],

      "&::after": { content: '"•"' },
    },
    ".isso-comment-footer": {
      gridColumn: "2 / 3",
      gridRow: "2 / 3",
    },
    ".isso-text-wrapper > .isso-postbox": { gridColumn: "1 / 3" },
  };

  return [sxShowHide, sxEltStyles, sxLayoutStyles].reduce(mergeDeepRight);
};

export const Comments = ({ meta }: { meta?: PostMeta }) => {
  const sx = useSx();
  const [isClient, setIsClient] = useState(false);
  const [didInit, setDidInit] = useState(false);

  useEffect(() => {
    setIsClient(true);

    const init = () => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const isso = (window as any)?.Isso?.init;
      if (isso) {
        isso();
        setDidInit(true);
      } else {
        setTimeout(init, 100);
      }
    };

    if (!document.getElementById("isso-script")) {
      const script = document.createElement("script");

      for (const [key, value] of Object.entries({
        id: "isso-script",
        src: `https://${SITE_URN}/blog-comments/js/embed.min.js`,
        "data-isso": `https://${SITE_URN}/blog-comments/`,
        "data-isso-css": "false",
        "data-isso-max-comments-nested": "inf",
        "data-isso-avatar": "false",
        "data-isso-vote": "false",
        "data-isso-page-author-hashes": "233a7c314654",
        "data-isso-reply-notifications-default-enabled": "true",
        "data-isso-postbox-text-text-en": "write a comment",
        "data-isso-postbox-author-placeholder-text-en": "name",
        "data-isso-postbox-email-text-en": "Email",
        "data-isso-postbox-email-placeholder-text-en": "email (not published)",
        "data-isso-postbox-notification-text-en":
          "Email me when someone replies",
        "data-isso-comment-confirm-text-en": "confirm?",
        "data-isso-comment-page-author-suffix-text-en": "author",
      })) {
        script.setAttribute(key, value);
      }

      document.body.appendChild(script);
    }

    setTimeout(init, 100);
  }, []);

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (window as any)?.Isso?.fetchComments?.();
  }, [meta?.url]);

  return (
    <Box
      as="section"
      id={isClient ? "isso-thread" : undefined}
      mx="auto"
      w="full"
      maxW="40em"
      p={4}
      data-title={meta?.title}
      data-isso-id={meta?.url}
      sx={sx}
    >
      <Text as="h2" textStyle="h2">
        Comments
      </Text>
      {didInit ? null : <Spinner />}
      <noscript>Javascript needs to be activated to view comments.</noscript>
    </Box>
  );
};
