import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";
import { Balancer } from "react-wrap-balancer";

import { PostMeta } from "../../renderer/types";
import { useColors } from "../useColors";

export const NavPreview = ({
  meta,
  prev,
}: {
  meta?: PostMeta;
  prev: boolean;
}) => {
  const { url, title, subtitle } = meta ?? {};
  const { linkBackground, subtitleColor } = useColors();

  return (
    <Box textAlign={prev ? "left" : "right"}>
      {meta ? (
        <Link
          href={url}
          display="flex"
          flexDirection="column"
          justifyContent="center"
          h="full"
          p={4}
          _hover={{
            textDecoration: "none",
            bg: linkBackground,
          }}
          _focus={{ bg: linkBackground }}
        >
          <Text
            fontFamily="sans"
            fontSize="xs"
            fontWeight="medium"
            color={subtitleColor}
          >
            {prev ? "Older" : "Newer"}
          </Text>
          <Text fontWeight="bold">
            <Balancer>{title}</Balancer>
          </Text>
          <Text fontStyle="italic">
            <Balancer>{subtitle}</Balancer>
          </Text>
        </Link>
      ) : null}
    </Box>
  );
};
