import { Box, Link, Text } from "@chakra-ui/react";
import React from "react";

import { TITLE } from "../../renderer/constants";
import { useColors } from "../useColors";

export const SiteHeader = () => {
  const { linkBackground } = useColors();

  return (
    <Box mx="auto" mt={10} w="full" maxW="40em" py={4}>
      <Link
        display="block"
        href="/"
        px={4}
        _hover={{
          textDecoration: "none",
          bg: linkBackground,
        }}
      >
        <Text fontSize="5xl" fontStyle="italic" fontWeight="thin">
          {TITLE}.
        </Text>
      </Link>
    </Box>
  );
};
